import { Establishment } from '@wls-solucoes/lets-eat-types';
import mixpanel from 'mixpanel-browser';
import { env } from '../../env';
import { store } from '../../redux/store';

function identify(userGuid: string) {
  if (!(window as any).isMixPanelInitialized) return;

  mixpanel.identify(userGuid);
}

function startSessionRecording() {
  if (!(window as any).isMixPanelInitialized) return;

  (mixpanel as any).start_session_recording();
}

function startTrackingNavigationTime() {
  if (
    !(window as any).isMixPanelInitialized ||
    (window as any).isTrackingNavigationTime
  ) {
    return;
  }

  (window as any).isTrackingNavigationTime = true;

  const eventSchedule = [1, 2, 3, 5, 10, 30, 45, 60];
  let navigationTime = 0;

  const timer = setInterval(() => {
    navigationTime += 1;

    if (eventSchedule.includes(navigationTime)) {
      track(`navigated-${navigationTime}min`);

      if (eventSchedule.indexOf(navigationTime) === eventSchedule.length - 1) {
        clearInterval(timer);
      }
    }
  }, 1000 * 60);
}

function init(userGuid: string, establishment?: Establishment) {
  mixpanel.init(env.REACT_APP_MIX_PANEL_TOKEN ?? '', {
    api_host: `https://${env.REACT_APP_MIX_PANEL_PROXY}`,
    debug: env.REACT_APP_MIX_PANEL_DEBUG === 'true',
    track_pageview: true,
    persistence: 'cookie',
    loaded: () => {
      (window as any).isMixPanelInitialized = true;

      identify(userGuid);
      startTrackingNavigationTime();

      if (establishment?.origin === 'trial') startSessionRecording();
    },
  });
}

function resetIdentification() {
  if (!(window as any).isMixPanelInitialized) return;

  mixpanel.reset();
}

function track(event: string, properties?: any) {
  if (!(window as any).isMixPanelInitialized) return;

  const establishmentGuid =
    store.getState().establishmentReducer.establishment?.guid;

  mixpanel.track(event, {
    ...properties,
    establishment_guid: [establishmentGuid],
  });
}

function stopSessionRecording() {
  if (!(window as any).isMixPanelInitialized) return;

  (mixpanel as any).stop_session_recording();
}

function logPageView(id: string) {
  track(`pageview-${id}`);
}

export const mixPanel = {
  init,
  identify,
  resetIdentification,
  track,
  startSessionRecording,
  stopSessionRecording,
  startTrackingNavigationTime,
  logPageView,
};
