import {
  DeliveryType,
  OrderSchedulingAvailableDate,
} from '@wls-solucoes/lets-eat-types';
import { AxiosResponse } from 'axios';
import {
  AverageTimeSettings,
  DeliverySettings,
  FeeByAreaSettings,
  FeeByDistanceModel,
  NeighborhoodFeeBatch,
  NeighborhoodFeeForm,
} from '../models/establishment';
import Api from './api';

const base = '/api/Delivery';

function insertFee(
  fee: NeighborhoodFeeForm,
  showMessage = false
): Promise<AxiosResponse> {
  return Api.post(`${base}/neighborhoodFee`, fee, {
    headers: {
      authHeader: true,
      successMessage: showMessage
        ? 'Taxa de entrega incluída com sucesso.'
        : undefined,
    },
  });
}

function updateFee(
  fee: NeighborhoodFeeForm,
  showMessage = false
): Promise<AxiosResponse> {
  return Api.put(`${base}/neighborhoodFee/${fee.guid}`, fee, {
    headers: {
      authHeader: true,
      successMessage: showMessage
        ? 'Taxa de entrega atualizada com sucesso.'
        : undefined,
    },
  });
}

function deleteFee(guid: string): Promise<AxiosResponse> {
  return Api.delete(`${base}/neighborhoodFee/${guid}`, {
    headers: {
      authHeader: true,
      successMessage: 'Taxa de entrega removida com sucesso.',
    },
  });
}

function batchFee(batch: NeighborhoodFeeBatch[]): Promise<AxiosResponse> {
  return Api.post(`${base}/neighborhoodFeeBatch`, batch, {
    headers: {
      authHeader: true,
    },
  });
}

function updateSettings(
  deliverySettings: DeliverySettings
): Promise<AxiosResponse> {
  return Api.put(`${base}`, deliverySettings, {
    headers: {
      authHeader: true,
    },
  });
}

function updateAverageTime(
  averageTimeSettings: AverageTimeSettings
): Promise<AxiosResponse> {
  return Api.put(`${base}/averageTime`, averageTimeSettings, {
    headers: {
      authHeader: true,
      successMessage: 'Informações de entrega atualizadas com sucesso.',
    },
  });
}

function getFeeByDistance(): Promise<AxiosResponse> {
  return Api.get(`${base}/feeByDistance`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function insertFeeByDistance(fee: FeeByDistanceModel): Promise<AxiosResponse> {
  return Api.post(`${base}/feeByDistance`, fee, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function deleteFeeByDistance(guid: string): Promise<AxiosResponse> {
  return Api.delete(`${base}/feeByDistance/${guid}`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function updateFeeByDistance(data: FeeByDistanceModel): Promise<AxiosResponse> {
  const { guid } = data;
  const fee = { ...data };
  delete fee.guid;
  return Api.put(`${base}/feeByDistance/${guid}`, fee, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function getFeeByArea(): Promise<AxiosResponse> {
  return Api.get(`${base}/feeByArea`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function insertFeeByArea(fee: FeeByAreaSettings): Promise<AxiosResponse> {
  return Api.post(`${base}/feeByArea`, fee, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function updateFeeByArea(fee: FeeByAreaSettings): Promise<AxiosResponse> {
  const { guid } = fee;
  return Api.put(`${base}/feeByArea/${guid ?? ''}`, fee, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function deleteFeeByArea(guid: string): Promise<AxiosResponse> {
  return Api.delete(`${base}/feeByArea/${guid}`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function getOrderSchedulingAvailableDates(
  establishmentGuid: string,
  deliveryType: DeliveryType
): Promise<OrderSchedulingAvailableDate[]> {
  return Api.get(
    `${base}/${establishmentGuid}/scheduling?deliveryType=${deliveryType}`
  ).then((res) => res.data);
}

export const deliveryServices = {
  getFeeByDistance,
  insertFeeByDistance,
  deleteFeeByDistance,
  updateFeeByDistance,
  getFeeByArea,
  insertFeeByArea,
  updateFeeByArea,
  deleteFeeByArea,
  insertFee,
  updateFee,
  batchFee,
  updateSettings,
  updateAverageTime,
  deleteFee,
  getOrderSchedulingAvailableDates,
};
