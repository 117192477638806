import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ExternalCommunicationProvider } from '@wls-solucoes/lets-eat-core';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import 'moment/locale/pt-br';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import MainRouter from './main-router';
import AppTheme from './shared/components/ApplicationTheme';
import GuideDialog from './shared/components/GuideDialog';
import ProgressIndicator from './shared/components/ProgressIndicator';
import { analytics } from './shared/services/analytics';
import './styles.css';

moment.locale('pt-br');
dayjs.locale('pt-br');
dayjs.extend(utc);

const RETRY_COUNT = 10;
export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: (failureCount, error: any) => {
        return (
          failureCount < RETRY_COUNT &&
          error.response?.data?.message === undefined
        );
      },
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const App: React.FC = () => {
  analytics.createCustomDimensions({
    dimension1: 'establishment_guid',
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ExternalCommunicationProvider disableFallback eventKey="lets_eat_panel">
        <AppTheme>
          <ProgressIndicator>
            <SnackbarProvider maxSnack={3} hideIconVariant>
              <GuideDialog />
              <MainRouter />
            </SnackbarProvider>
          </ProgressIndicator>
        </AppTheme>
      </ExternalCommunicationProvider>
    </QueryClientProvider>
  );
};

export default App;
