import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { appHistory } from '../../helpers/utils/appHistory';
import { store } from '../../redux/store';
import { USER_LOGOUT } from '../../redux/types';
import { AUTHENTICATION_REQUIRED_HEADER } from '../interceptors/headers';
import { LOGOUT } from '../models/eventGA';
import { TokenResponse } from '../models/tokenResponse';
import { analytics } from './analytics';
import Api from './api';
import { AuthService } from './authService';
import { mixPanel } from './mixPanel';

const base = '/api/User';
const tokenBase = '/api/token';

function login(
  email?: string,
  password?: string,
  token?: string
): Promise<TokenResponse> {
  if (email && password) {
    return Api.post<TokenResponse>(
      `${tokenBase}/pre_login`,
      { email, password },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then((resp) => {
      const { data } = resp;
      AuthService.setAuth({
        preLoginToken: data.preLoginToken,
        accessToken: data.accessToken,
        establishment:
          data.establishments?.length === 1
            ? data.establishments[0]
            : undefined,
      });
      return data;
    });
  }

  return Api.post<TokenResponse>(
    `${tokenBase}/firstLogin`,
    { firstLoginToken: token },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
    .then((resp) => {
      const { data } = resp;
      AuthService.setAuth({
        preLoginToken: data.preLoginToken,
        accessToken: data.accessToken,
        establishment:
          data.establishments?.length === 1
            ? data.establishments[0]
            : undefined,
      });
      return data;
    })
    .catch((err) => {
      appHistory.push('/login');
      return err;
    });
}

function me(token?: string): Promise<AxiosResponse> {
  return Api.get(`${base}/me`, {
    headers: token
      ? {
          'x-api-version': '2',
          Authorization: `Bearer ${token}`,
        }
      : ({
          AUTHENTICATION_REQUIRED_HEADER,
        } as any),
  });
}

function confirmEmail(email: string, token: string): Promise<any> {
  return Api.post('/api/User/confirm', { email, token })
    .then(() => toast.success('E-mail confirmado com sucesso.'))
    .catch(() => toast.error('Erro ao confirmar e-mail.'));
}

function logout(): void {
  mixPanel.resetIdentification();
  AuthService.removeAuth();
  sessionStorage.removeItem('electrode');
  store.dispatch({ type: USER_LOGOUT });
  analytics.eventsGA(LOGOUT, '');
  window.Beamer?.destroy();

  if (appHistory.location.pathname !== '/login') {
    appHistory.push('/login');
  }
}

export const userService = {
  login,
  logout,
  me,
  confirmEmail,
};
